<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
        </b-link>

        <!-- <b-card-title class="mb-1">{{ $t('src.views.pages.auth.login.welcomeTV') }}</b-card-title>
        <b-card-text class="mb-2">{{ $t('src.views.pages.auth.login.pleaseST') }}</b-card-text> -->

        <!-- form -->
        <validation-observer ref="loginForm">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              :label="$t('src.views.pages.auth.login.email')"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  :placeholder="$t('src.views.pages.auth.login.johnexamplecom')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('src.views.pages.auth.login.password') }}</label>
                <b-link :to="{name:'forgetPassword'}">
                  <label style="cursor:pointer;">{{ $t('src.views.pages.auth.login.forgotP') }}</label>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    :placeholder="$t('src.views.pages.auth.login.7451')"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >{{ $t('src.views.pages.auth.login.rememberM') }}</b-form-checkbox>
              </b-form-group> -->

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
              @click="validationForm"
            >
              <b-spinner
                v-if="loading"
                small
                class="float-left"
                :label="$t('src.views.pages.auth.login.floatedR')"
              />{{ $t('src.views.pages.auth.login.logI') }}</b-button>
          </b-form>
        </validation-observer>

        <b-button
          type="button"
          variant="outline-primary"
          class="mt-2"
          block
          :to="{name:'register'}"
        >{{ $t('src.views.pages.auth.login.createA') }}</b-button>

        <!-- <b-card-text class="text-center mt-2">
          <span>{{ $t('src.views.pages.auth.login.newOOP') }}</span>
          <b-link :to="{name:'register'}">
            <span>{{ $t('src.views.pages.auth.login.createAA') }}</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <font-awesome-icon icon="fa-brands fa-facebook-f" />{{ $t('src.views.pages.auth.login.facebookS') }}<feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <font-awesome-icon icon="fa-brands fa-google" />{{ $t('src.views.pages.auth.login.googleS') }}<feather-icon icon="PlusIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BFormGroup, BFormInput, BInputGroupAppend, BSpinner, BInputGroup, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {

    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      const self = this
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          self.loading = true
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              self.loading = false
              if (response.data.code === 401) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Invalid username or password',
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }

              if (response.data?.status) { // if loggin successfull
                const { userdata } = response.data
                useJwt.setToken(response.data.token)
                const userData = userdata
                localStorage.setItem('userData', JSON.stringify(userdata))
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role_id))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${userData.name || userData.email}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'You have successfully logged in.',
                      },
                    })
                  })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
